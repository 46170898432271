<template>
  <publicHeader />
  <div class="appContent">
    <router-view></router-view>
  </div>
  <publicFooter />
  <el-backtop :right="100" :bottom="100" />
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
body {
  background-color: #f5f5f5;
}

.appContent {
  width: $publicMaxAndWidth;
  margin: 0 auto;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #dadada;
}

::-webkit-scrollbar-thumb {
  background-color: #999;
}
</style>
