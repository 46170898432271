<template>
  <div>
    <el-carousel direction="vertical" :autoplay="true" loop indicator-position="none" :interval="5000">
      <el-carousel-item v-for="item in illustrateDataList" :key="item.id">
        <!-- 目标和使命 -->
        <div class="illustrate">
          <svg
            t="1703139929278"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="9401"
          >
            <path
              d="M511.999488 65.290005C265.29776 65.290005 65.30075 265.301342 65.30075 511.988744c0 246.694565 199.995987 446.720228 446.697715 446.720228s446.697715-200.025663 446.697715-446.720228C958.697203 265.301342 758.701217 65.290005 511.999488 65.290005L511.999488 65.290005zM511.999488 783.214005c-33.153074 0-60.011792-26.885324-60.011792-60.039421 0-33.139771 26.857694-60.025095 60.011792-60.025095s60.010769 26.885324 60.010769 60.025095C572.009234 756.328681 545.152563 783.214005 511.999488 783.214005L511.999488 783.214005zM586.390779 342.710362l-14.381545 215.381395c0 33.153074-26.856671 60.039421-60.010769 60.039421s-60.011792-26.885324-60.011792-60.039421l-14.380522-215.381395c-0.407276-3.191692-0.64059-6.426363-0.64059-9.735735 0-41.440831 33.590026-75.030857 75.03188-75.030857 41.440831 0 75.030857 33.590026 75.030857 75.030857C587.030345 336.282976 586.798055 339.517647 586.390779 342.710362L586.390779 342.710362zM597.150844 316.994678"
              fill="#d81e06"
              p-id="9402"
            ></path>
          </svg>
          {{ item.content }}
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script setup>
import homeStore from "@/store/modules/home";
const { illustrateDataList } = homeStore();
</script>

<style lang="scss" scoped>
.illustrate {
  background-color: #fff;
  padding: 10px 0px;
  letter-spacing: 1px;
  font-size: 14px;
  color: #333;
  margin-top: 10px;
  border-radius: 6px;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    color: #000;
  }
}

::v-deep(.el-carousel__container) {
  height: 50px;
  overflow: hidden;
}
</style>
