<template>
  <div class="footer">
    <div class="footerSon">
      <p>
        本网站所有录载的法律条文信息均来自于：
        <span style="display: block">
          《中国人民共和国刑法》、《中国人民共和国劳动法》、《中华人民共和国劳动合同法》、
          《中国人民共和国刑法》、《中华人民共和国婚姻法》等<a href=""
            >其他法</a
          >
        </span>
      </p>

      <p>
        本网站全部内容，未经授权禁止转载、摘编、复制及建立镜像，违者将依法追究法律责任。
      </p>

      <p>
        为了防范电信网络诈骗，如遇违法和不良信息请点击<a href="">进行举报</a>
        邮箱:pstart2023@163.com
      </p>
    </div>
    <div class="copyright">
      <p>
        Copyright © 2021-2023 普网pstart.club 版权所有<a
          href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
          target="_blank"
        >
          [鲁ICP备2021045895号]</a
        >
      </p>
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  margin-top: 20px;
  // background-color: #fafafa;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("@/assets/images/footerBg.jpg");
  background-position: center center;
  user-select: none;
  font-size: 14px;
  a {
    color: #fff;
  }
  .footerSon {
    width: $publicMaxAndWidth;
    padding: 20px 40px;
    margin: 0 auto;
    text-align: center;
    color: #fff;
    > p {
      margin-bottom: 10px;
    }
  }
  .copyright {
    border-top: 1px solid #fff;
    padding: 14px 0px;
    text-align: center;
    color: #fff;
  }
}
</style>
