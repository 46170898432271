import { defineStore } from "pinia";
const homeStore = defineStore("homes", {
  state: () => {
    return {
      illustrateDataList: [
        {
          id: 1,
          content:
            "坚决抵抗和反对一切违法损害和压迫人民群众利益的违法行为, 为受到不法侵害和压迫的人民提供有力帮助 ——普网",
        },
        {
          id: 2,
          content:
            "对待善良之人，有半点欺凌就是造孽。对待歹毒之人，有半点仁慈就是作恶 ——曹操",
        },
        {
          id: 3,
          content: "我们花了两年学会了说话，却要花上60年学会闭嘴 ——海明威",
        },
        {
          id: 4,
          content:
            "一个人的性格决定你的境遇，如果你坚持保留你的性格，那你就无权拒绝你的境遇 ——罗曼罗兰",
        },
        {
          id: 5,
          content:
            "每个人都有自己的天赋，如果用会不会爬树的能力去评判一条鱼，它终其一生以为自己愚蠢——爱因斯坦",
        },
        {
          id: 6,
          content:
            "只要你肯吃苦，这辈子就有吃不完的苦；只要你肯受气，这辈子就有受不完的气；只要你肯干活，这辈子就有干不完的活；只要你肯享受，这辈子就有享不尽的福。",
        },
      ],
    };
  },
});

export default homeStore;
