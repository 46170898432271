<template>
  <div class="developing">
    <el-empty description="功能模块开发中..." />
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.developing {
  width: 100%;
  height: 100%;
  text-align: center;
}
</style>
