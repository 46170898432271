import { createApp, nextTick } from "vue";
import App from "./App.vue";
import ElementPlus, { ElMessage } from "element-plus";
import "element-plus/dist/index.css";
// 引入element-plus-icons
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
// 清空默认样式
import "@/assets/style/default.css";
// 引入路由文件
import router from "@/router";
// 引入状态管理Pinia
import { createPinia } from "pinia";
const State = createPinia();
// 应用实例
const app = createApp(App);
// 引入全局组件
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Pillustrate from "@/components/Pillustrate/index.vue";
import Developing from "@/components/Developing/index.vue";
// 注册全局组件
app.component("Pillustrate", Pillustrate);
app.component("Developing", Developing);
app.component("publicHeader", Header);
app.component("publicFooter", Footer);

nextTick(() => {
  app.config.globalProperties.$Message = ElMessage;
});

// 注册全部图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
// 使用并挂载
app.use(router).use(ElementPlus).use(State).mount("#app");
