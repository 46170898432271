import { createRouter, createWebHistory } from "vue-router";

// 创建路由
const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      title: "首页",
    },
    component: () => import("@/views/Home"),
  },
  {
    path: "/News",
    name: "News",
    meta: {
      title: "新闻",
    },
    component: () => import("@/views/News"),
  },
  {
    path: "/Welfare",
    name: "Welfare",
    meta: {
      title: "公益同盟",
    },
    component: () => import("@/views/Welfare"),
  },
  {
    path: "/Law",
    name: "Law",
    meta: {
      title: "法律事务",
    },
    component: () => import("@/views/Law"),
  },
  {
    path: "/President",
    name: "President",
    meta: {
      title: "主席文化",
    },
    component: () => import("@/views/President"),
  },
  {
    path: "/Books",
    name: "Books",
    meta: {
      title: "书籍屋子",
    },
    component: () => import("@/views/Books"),
  },
  {
    path: "/Questions",
    name: "Questions",
    meta: {
      title: "法问答",
    },
    component: () => import("@/views/Questions"),
  },
  {
    path: "/UserCenter",
    name: "UserCenter",
    meta: {
      title: "个人主页",
    },
    component: () => import("@/views/UserCenter"),
    children: [
      {
        name: "UserHome",
        path: "/UserCenter/UserHome",
        meta: {
          title: "首页",
        },
        component: () => import("@/views/UserCenter/UserHome"),
      },
      {
        name: "UserCreate",
        path: "/UserCenter/UserCreate",
        meta: {
          title: "普法创作",
        },
        component: () => import("@/views/UserCenter/UserCreate"),
      },
      {
        name: "UserIntegral",
        path: "/UserCenter/UserIntegral",
        meta: {
          title: "个人积分",
        },
        component: () => import("@/views/UserCenter/UserIntegral"),
      },
      {
        name: "UserInfo",
        path: "/UserCenter/UserInfo",
        meta: {
          title: "个人信息",
        },
        component: () => import("@/views/UserCenter/UserInfo"),
      },
      {
        name: "UserMedal",
        path: "/UserCenter/UserMedal",
        meta: {
          title: "我的勋章",
        },
        component: () => import("@/views/UserCenter/UserMedal"),
      },
      {
        name: "UserHistory",
        path: "/UserCenter/UserHistory",
        meta: {
          title: "浏览记录",
        },
        component: () => import("@/views/UserCenter/UserHistory"),
      },
      {
        name: "UserCollect",
        path: "/UserCenter/UserCollect",
        meta: {
          title: "我的收藏",
        },
        component: () => import("@/views/UserCenter/UserCollect"),
      },
      {
        name: "UserInvite",
        path: "/UserCenter/UserInvite",
        meta: {
          title: "邀请注册",
        },
        component: () => import("@/views/UserCenter/UserInvite"),
      },
      {
        name: "UserRealName",
        path: "/UserCenter/UserRealName",
        meta: {
          title: "实名认证",
        },
        component: () => import("@/views/UserCenter/UserRealName"),
      },
    ],
  },
];

// 创建路由器
const Router = createRouter({
  history: createWebHistory(),
  routes,
});

// 路由前置守卫
Router.beforeEach((to, from, next) => {
  next();
});

// 路由后置守卫
Router.afterEach((to, from) => {
  let illustrate = "坚决对抗一切违法犯罪行为";
  document.title = `普网(PSTART.CLUB)-${to.meta.title}-${illustrate}`;
});

export default Router;
