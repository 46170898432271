export const navListData = [
  {
    navName: "首页",
    navPath: "/",
  },
  {
    navName: "求问答",
    navPath: "/Questions",
  },
  {
    navName: "求法援",
    navPath: "/Law",
  },
  {
    navName: "最新新闻",
    navPath: "/News",
  },
  {
    navName: "公益同盟",
    navPath: "/Welfare",
  },
  {
    navName: "普美书屋",
    navPath: "/Books",
  },{
    navName: "主席文化",
    navPath: "/President",
  },
];
