<template>
  <div
    class="userOperation"
    :class="[props.isShow ? 'userOperationShow' : null]"
  >
    <div class="OperationMain">
      <img
        class="loginClose"
        src="@/assets/images/login/icons/close.png"
        alt="关闭图标"
        @click.stop="closeHandle"
      />
      <div class="loginCoverImg"></div>
      <div style="width: 280px">
        <div class="title">欢迎登录</div>
        <div class="enTitle">Welcome Login</div>
        <div>
          <el-form ref="ruleFormRef" :model="loginForm" :rules="dataFromRules">
            <el-form-item prop="account">
              <el-input
                v-model="loginForm.account"
                placeholder="请输入手机号/账号"
                prefix-icon="User"
                clearable
              />
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                type="password"
                show-password
                v-model="loginForm.password"
                placeholder="请输入登陆密码"
                prefix-icon="Lock"
                clearable
              />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="login(ruleFormRef)"
                >登 录</el-button
              >
            </el-form-item>
            <div class="descriptions">
              <p>
                温馨提示：未注册过的用户，系统会自动帮您注册账号，登录或完成注册即代表您已阅读和同意<span
                  @click="agreementHandle"
                  >用户协议</span
                >和<span @click="privacyHandle">隐私政策</span>
              </p>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from "vue";
const $emits = defineEmits(["changeIsShow"]);
const props = defineProps({
  // 控制组件是否显示
  isShow: {
    type: Boolean,
    default: false,
  },
});
const ruleFormRef = ref();
const dataFromRules = reactive({
  account: [
    {
      required: true,
      trigger: "change",
      message: "请输入您的手机号或账号",
    },
  ],
  password: [
    {
      required: true,
      trigger: "change",
      message: "请输入您的登录密码",
    },
  ],
});

const loginForm = reactive({ account: "", password: "" });

// 隐私
const agreementHandle = () => {};
// 协议
const privacyHandle = () => {};

// 关闭当前组件显示
const closeHandle = () => {
  $emits("changeIsShow", false);
  resetLoginForm();
};

// 清空表单内容
const resetLoginForm = () => {
  loginForm.account = "";
  loginForm.password = "";
};

// 点击登录
const login = async (formEl) => {
  await formEl.validate((isTrue) => {
    if (!isTrue) return;
    closeHandle();
  });
};
</script>

<style lang="scss" scoped>
.userOperation {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transform: scale(0);
  transition: opacity 0.5s;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  .loginCoverImg {
    width: 400px;
    background-image: url("/src/assets/images/login/bg.jpg");
    background-size: cover;
    margin-right: 30px;
    border-radius: 10px;
    background-position: center center;
  }
  .OperationMain {
    position: relative;
    padding: 30px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    flex-flow: row nowrap;
    .loginClose {
      width: 25px;
      height: 25px;
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;
    }
    .title {
      font-size: 20px;
      font-weight: bold;
    }
    .enTitle {
      padding-bottom: 20px;
      color: $publicDesMainColor;
    }
    .descriptions {
      font-size: 14px;
      color: $publicDesMainColor;
      margin-top: 10px;
      > p {
        span {
          color: $publicColor0280f0;
          cursor: pointer;
        }
      }
    }
    ::v-deep(.el-input__wrapper) {
      padding: 5px 15px;
    }
    ::v-deep(.el-button) {
      width: 100% !important;
      padding: 18px 0px;
    }
  }
}

.userOperationShow {
  opacity: 1;
  transform: scale(1);
}
</style>
