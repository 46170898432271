<template>
  <div class="Header">
    <div class="HeaderSon">
      <!-- logo -->
      <img src="@/assets/logo.png" alt="logo" @click="$router.push('/')" />
      <div class="right">
        <!-- 导航栏 -->
        <div class="navContent">
          <div
            class="navSon"
            :class="[$route.path == item.navPath ? 'navSonCurrent' : null]"
            v-for="(item, index) in navList"
            :key="index"
            @click="toPageHandle(item)"
          >
            {{ item.navName }}
          </div>
        </div>
        <!-- 搜索栏 -->
        <div class="headerSearch">
          <el-input
            v-model="input"
            placeholder="请输入要查询的法问"
            suffix-icon="Search"
            clearable
          />
        </div>
        <!-- 登录和注册 -->
        <div class="login" @click="loginHandle">
          <span>登录/注册</span>
        </div>
      </div>
    </div>
  </div>
  <!-- 用户登录 -->
  <userOperation :isShow="isShow" @changeIsShow="changeIsShowHandle" />
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import userOperation from "@/components/UserOperation";
import { navListData } from "./data";
const $route = useRoute();
const $router = useRouter();
// 公共导航数据
let navList = ref(navListData);
// 处理用户登录弹窗
let isShow = ref(false);
onMounted(() => {
  console.log($router);
});

// 跳转路由
const toPageHandle = (item) => {
  const { navPath } = item;
  $router.push({
    path: navPath,
  });
};

// 跳转登录页面
const loginHandle = () => {
  isShow.value = true;
};

// 修改登录页面的显示
const changeIsShowHandle = (event) => {
  isShow.value = event;
};
</script>

<style lang="scss" scoped>
.Header {
  background-image: url("@/assets/images/headerBg.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  position: sticky;
  top: 0px;
  user-select: none;
  z-index: 999;

  .HeaderSon {
    width: $publicMaxAndWidth;
    min-width: $publicMaxAndWidth;
    margin: 0 auto;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    .right {
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 16px;

      .navContent {
        display: flex;
        flex-flow: row nowrap;
        gap: 30px;

        .navSon {
          color: $publicColorFFF;
          cursor: pointer;
          transition: all 0.3s;
          position: relative;
          text-shadow: 1px 1px 1px #000;
          letter-spacing: 1.5px;
        }

        .navSon::before {
          width: 0px;
          height: 4px;
          background-color: transparent;
          border-radius: 10px;
          content: " ";
          position: absolute;
          bottom: -14px;
          left: 50%;
          z-index: 100;
          transform: translateX(-50%);
          transition: 0.3s all;
        }

        .navSonCurrent {
          color: #fff;
        }

        .navSonCurrent::before {
          content: " ";
          background-color: #fff;
          bottom: -14px;
          width: 20px;
        }
      }
      .headerSearch {
        margin: 0 20px;
      }
      .login {
        padding: 4px 14px;
        border: 1px solid #0554cc;
        background-color: #0554cc;
        border-radius: 50px;
        > span {
          font-size: 14px;
          cursor: pointer;
        }
      }
    }
    > img {
      width: 150px;
      margin-right: 30px;
      cursor: pointer;
    }
  }
}
</style>
